const prod = {
  baseURL: `https://api.optionstradehub.com/api/v1`,
  apiURL: `https://api.optionstradehub.com`,
};

const dev = {
  // baseURL: `http://localhost:7002/api/v1`,
  // apiURL: `http://localhost:7002`,
  baseURL: `https://api.optionstradehub.com/api/v1`,
  apiURL: `https://api.optionstradehub.com`,
};

export const config = process.env.NODE_ENV === `development` ? dev : prod;
